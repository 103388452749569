import { template as template_af73475885274133a1abec4c31f6fadf } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_af73475885274133a1abec4c31f6fadf(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
