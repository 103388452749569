import { template as template_5d19834630794640bc7a03aa08968ba2 } from "@ember/template-compiler";
const FKLabel = template_5d19834630794640bc7a03aa08968ba2(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
